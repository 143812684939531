import gql from 'graphql-tag'
import { apolloClient, ERROR_NOT_FOUND } from '@/apis/graphql/client'
import { getSignedInStudent, getStudent } from '@/apis/graphql/query/queries'
import {
  type ChangeOwnPasswordMutation,
  type GetSignedInStudentQuery,
  type GetSignedInStudentResponse,
  type GetStudentQuery,
  type GetStudentResponse,
  type OwnPasswordChangeInput
} from '@/types/codegen/schema'
import { changeOwnPassword } from '@/apis/graphql/query/mutations'

export namespace StudentRepoResponse {
  export type FetchSignedIn = GetSignedInStudentResponse
  export type FetchDetail = GetStudentResponse
}
export const studentRepo = {
  /* サインインしている生徒情報取得 */
  fetchSignedIn: async (username: string) => {
    const { data } = await apolloClient.query<GetSignedInStudentQuery>({
      variables: { username },
      query: gql(getSignedInStudent)
    })
    if (!data || !data.getStudentByUsername) throw ERROR_NOT_FOUND

    return data.getStudentByUsername
  },
  /** アカウントページで表示する生徒情報を取得する */
  fetchDetail: async (id: number) => {
    const { data } = await apolloClient.query<GetStudentQuery>({
      query: gql(getStudent),
      variables: {
        id
      }
    })
    if (!data || !data.getStudent) throw ERROR_NOT_FOUND

    return data.getStudent
  },
  async changePassword(input: OwnPasswordChangeInput) {
    const { data } = await apolloClient.mutate<ChangeOwnPasswordMutation>({
      mutation: gql(changeOwnPassword),
      variables: { input }
    })
    if (!data || !data.changeOwnPassword) throw ERROR_NOT_FOUND
    return data.changeOwnPassword
  }
}
