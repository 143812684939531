import _dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isBetween from 'dayjs/plugin/isBetween';
import ja from 'dayjs/locale/ja';
_dayjs.extend(isSameOrBefore.default);
_dayjs.extend(isBetween.default);
_dayjs.locale(ja);
export var dayjs = _dayjs;
export default (function (_ctx, inject) {
  inject('dayjs', dayjs);
});