import { render, staticRenderFns } from "./NavigationDrawerProfile.vue?vue&type=template&id=c0d60c0c&scoped=true"
import script from "./NavigationDrawerProfile.vue?vue&type=script&lang=ts"
export * from "./NavigationDrawerProfile.vue?vue&type=script&lang=ts"
import style0 from "./NavigationDrawerProfile.vue?vue&type=style&index=0&id=c0d60c0c&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0d60c0c",
  null
  
)

export default component.exports