import { DevLogger } from './impl/dev';
import { LocalLogger } from './impl/local';
import { PrdLogger } from './impl/prd';
export var logger = function () {
  var stageName = process.env.STAGE_NAME;
  var appName = process.env.APP_NAME;
  var appVersion = process.env.npm_package_version || 'unknown';
  var sentryDsn = process.env.SENTRY_DSN || 'unknown';
  switch (stageName) {
    case 'production_parent':
    case 'production_student':
      return new PrdLogger({
        sentryDsn: sentryDsn,
        appName: appName,
        stageName: stageName,
        appVersion: appVersion
      });
    case 'development_parent':
    case 'development_student':
    case 'staging_student':
    case 'staging_parent':
      return new DevLogger({
        sentryDsn: sentryDsn,
        appName: appName,
        stageName: stageName,
        appVersion: appVersion
      });
    case 'local':
    default:
      return new LocalLogger();
  }
}();
var LoggerProvider = function LoggerProvider(ctx, inject) {
  logger.initErrorTracker(ctx.store.state.user.username || '');

  // NOTE: logger.initSessionReplay is lazy called on middleware(~/middleware/initSessionReplay.ts)
  // logger.initSessionReplay()

  inject('logger', logger);
};
export default LoggerProvider;