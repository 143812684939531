export const changeParentOwnPassword = /* GraphQL */ `
  mutation changeParentOwnPassword($input: OwnPasswordChangeInput!) {
    changeParentOwnPassword(input: $input) {
      id
    }
  }
`
export const changeOwnPassword = /* GraphQL */ `
  mutation changeOwnPassword($input: OwnPasswordChangeInput!) {
    changeOwnPassword(input: $input) {
      id
    }
  }
`
// 保護者用おしらせの既読処理
export const registerReadNotificationForParent = /* GraphQL */ `
  mutation registerReadNotificationForParent($input: RegisterReadNotificationParentInput!) {
    registerReadNotificationForParent(registerReadNotificationParentInput: $input) {
      id
    }
  }
`
// 生徒用おしらせの既読処理
export const registerReadNotificationForStudent = /* GraphQL */ `
  mutation registerReadNotificationForStudent($input: RegisterReadNotificationStudentInput!) {
    registerReadNotificationForStudent(registerReadNotificationStudentInput: $input) {
      id
    }
  }
`

/** 保護者メール */
export const createParentEmail = /* GraphQL */ `
  mutation createParentEmail($input: CreateParentEmailInput!) {
    createParentEmail(input: $input) {
      id
    }
  }
`
export const updateParentEmail = /* GraphQL */ `
  mutation updateParentEmail($input: UpdateParentEmailInput!) {
    updateParentEmail(input: $input) {
      id
    }
  }
`
export const deleteParentEmail = /* GraphQL */ `
  mutation deleteParentEmail($input: DeleteParentEmailInput!) {
    deleteParentEmail(input: $input) {
      id
    }
  }
`

export const bulkRegisterParentReadGuidanceReports = /* GraphQL */ `
  mutation bulkRegisterParentReadGuidanceReports($input: ParentReadGuidanceReportsBulkRegisterInput!) {
    bulkRegisterParentReadGuidanceReports(input: $input)
  }
`
