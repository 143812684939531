import { Amplify } from '@aws-amplify/core'

export default function () {
  const region = process.env.AWS_REGION
  const userPoolId = process.env.AWS_AMPLIFY_AUTH_USER_POOL_ID
  const userPoolWebClientId = process.env.AWS_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID
  const identityPoolId = process.env.AWS_COGNITO_ID_POOL_ID

  Amplify.configure({
    Auth: {
      region,
      userPoolId,
      userPoolWebClientId,
      identityPoolId,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      clientMetadata: { appName: process.env.APP_NAME === 'RECO_FOR_PARENT' ? 'forParent' : 'forStudent' }
    }
  })
}
