export const toLocaleStr = (val: string | number, defaultValue: string = '-') => {
  if (['0', '0.0', 0].includes(val)) return '0'
  const num = Number(val)
  if (!val) return defaultValue
  return Number.isFinite(num) ? num.toLocaleString() : defaultValue
}

// dayjs用の日付・時間のフォーマット
export const DATE_FORMATS = {
  yearMonthDateHourMinute: 'YYYY/M/D(dd) H:mm',
  yearMonthDate: 'YYYY/M/D(dd)',
  yearMonth: 'YYYY/M',
  year: 'YYYY',
  hourMinute: 'H:mm',
  // NOTE: vuetifyの日付を扱うコンポーネントには日付を'YYYY-MM-DD'や'YYYY-MM'にフォーマットして渡す必要がある
  yearMonthDateForVuetify: 'YYYY-MM-DD',
  yearMonthDateHourMinuteForVuetify: 'YYYY-MM-DD HH:mm',
  dayOfWeek: 'dd'
} as const

export const groupBy = <V>(arr: readonly V[], key: keyof V & string) => {
  const result: { [k in string]: V[] } = {}

  arr.forEach(obj => {
    const groupingKey = typeof obj[key] === 'number' ? String(obj[key]) : obj[key]
    if (typeof groupingKey === 'string' && groupingKey in result) {
      result[groupingKey].push(obj)
    } else if (typeof groupingKey === 'string') {
      result[groupingKey] = [obj]
    }
  })

  return result
}

type CloneTarget = { [k in string]: any }
type ArgType = string | number | boolean | null | undefined | CloneTarget | ArgType[]

const cloneArray = <T extends ArgType[]>(array: T): T => {
  // FIXME: 型エラーが解けない. 利用側の推論は正しい.
  // @ts-ignore
  return array.map(el => {
    if (Array.isArray(el)) return cloneArray(el)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    else if (typeof el === 'object' && el !== null) return cloneDeep(el as CloneTarget)
    else if (
      typeof el === 'string' ||
      typeof el === 'number' ||
      typeof el === 'boolean' ||
      typeof el === 'undefined' ||
      el === null
    ) {
      return el
    } else {
      throw new TypeError(
        '処理できない型の引数が与えられました. キーがstringではないオブジェクト, 値に関数などが含まれる場合処理できません.'
      )
    }
  })
}

export const cloneDeep = <T extends (object & CloneTarget) | unknown[]>(target: T): T => {
  // TODO: as 取れたら取る
  if (typeof target === 'object' && Array.isArray(target)) return cloneArray(target) as T
  const obj: CloneTarget = {}
  Object.keys(target).forEach(key => {
    if (typeof key !== 'string')
      throw new TypeError(
        '処理できない型の引数が与えられました. キーがstringではないオブジェクト, 値に関数などが含まれる場合処理できません.'
      )
    const val = target[key]
    if (typeof val === 'object' && Array.isArray(val)) {
      obj[key] = cloneArray(val)
    } else if (typeof val === 'object' && val !== null) {
      obj[key] = cloneDeep(val as CloneTarget)
    } else if (
      typeof val === 'string' ||
      typeof val === 'number' ||
      typeof val === 'boolean' ||
      typeof val === 'undefined' ||
      val === null
    ) {
      obj[key] = val
    } else {
      throw new TypeError(
        '処理できない型の引数が与えられました. キーがstringではないオブジェクト, 値に関数などが含まれる場合処理できません.'
      )
    }
  })
  return obj as T
}
