
import { defineComponent, useMeta } from '@nuxtjs/composition-api'
export default defineComponent({
  layout: 'plain',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const errorTitle = props.error.statusCode === 404 ? '404' : 'ERROR!'

    const errorMessage =
      props.error.statusCode === 404 ? 'ご指定のページが見つかりませんでした。' : 'エラーが発生しました。'

    useMeta({ title: errorMessage })

    return {
      errorTitle,
      errorMessage
    }
  },
  head: {}
})
