import { reactive } from '@nuxtjs/composition-api'
import type { InjectionKey } from 'vue'
import type VueI18n from 'vue-i18n/types'
import { createDefaultSnackbarItem } from '~/modules/snackBar'

export interface SnackbarItem {
  isOpened: boolean
  title: VueI18n.TranslateResult
  link?: SnackBarLink
}
export interface SnackBarLink {
  text: string
  path: string
}
export type Snackbar = ReturnType<typeof useSnackbar>

/**
 * InjectionKey
 */
export const SnackbarKey: InjectionKey<Snackbar> = Symbol('Snackbar')

/**
 * useCase
 */
export const useSnackbar = () => {
  const snackbarItem: SnackbarItem = reactive(createDefaultSnackbarItem())

  const openSnackbar = (item: Omit<SnackbarItem, 'isOpened'>) => {
    Object.assign(snackbarItem, { title: item.title })
    item.link && Object.assign(snackbarItem, { link: item.link })
    snackbarItem.isOpened = true
  }

  const closeSnackbar = () => Object.assign(snackbarItem, createDefaultSnackbarItem())

  return { snackbarItem, openSnackbar, closeSnackbar }
}
