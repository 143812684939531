import { type LoggerInterface } from '..'

import { maskPropertiesOfEncryptionRequirements } from '~/plugins/logger/sanitize'
import { cloneDeep } from '@/modules/util'

export class LocalLogger implements LoggerInterface {
  initErrorTracker(): void {
    /** do nothing */
  }

  initSessionReplay(): void {
    /** do nothing */
  }

  identify(_uid: string, _data: Record<string, string>): void {
    /** do nothing */
  }

  stopSessionReplay(): void {}

  restartSessionReplay(): void {}

  captureException(_e: unknown): void {
    /** do nothing */
  }

  log(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.log(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  debug(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.debug(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  info(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.info(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  warn(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.warn(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  error(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.error(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }
}
