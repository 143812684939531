import { gql } from '@apollo/client/core'
import { apolloClient, ERROR_NOT_FOUND } from '@/apis/graphql/client'
import { getJukuConfigByJukuId } from '@/apis/graphql/query/queries'
import { type GetJukuConfigByJukuIdQuery } from '@/types/codegen/schema'

export const jukuConfigRepo = {
  /**
   * 教室を取得する.
   */
  fetch: async (jukuId: number) => {
    const { data } = await apolloClient.query<GetJukuConfigByJukuIdQuery>({
      variables: { jukuId },
      query: gql(getJukuConfigByJukuId)
    })
    if (!data) throw ERROR_NOT_FOUND
    console.debug('getJukuConfigByJukuId', data.getJukuConfigByJukuId)
    return { ...data.getJukuConfigByJukuId }
  }
}
