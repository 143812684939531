
import { defineComponent } from '@nuxtjs/composition-api'
import type { PropType } from 'vue'
import { dayjs } from '@/plugins/dayjs'
import { type Student } from '@/types/codegen/schema'
import { DATE_FORMATS } from '@/modules/util'

export default defineComponent({
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true
    }
  },
  setup() {
    return { DATE_FORMATS, dayjs }
  }
})
