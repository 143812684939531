import * as Sentry from '@sentry/browser'

import { type LoggerInterface } from '..'

import { maskPropertiesOfEncryptionRequirements, sanitizerForReplayFrameEvent } from '~/plugins/logger/sanitize'
import { type AppName } from '~/nuxt.config'
import { cloneDeep } from '@/modules/util'

type LoggerEnv = {
  sentryDsn: string
  appName: AppName
  stageName: string
  appVersion: string
}

export class DevLogger implements LoggerInterface {
  private sentry = Sentry
  private replay: ReturnType<typeof Sentry.replayIntegration> | null = null
  private isInitializedSentry = false

  constructor(private env: LoggerEnv) {}

  initErrorTracker(username: string): void {
    if (this.isInitializedSentry) return
    this.sentry.init({
      dsn: this.env.sentryDsn,
      environment: `${this.env.appName}-${this.env.stageName}`,
      release: this.env.appVersion,
      integrations: [
        this.sentry.browserTracingIntegration({
          enableInp: true
        })
      ],
      ignoreErrors: [
        /Unexpected token '<'/, // デプロイ直後のキャッシュのせいでなるエラー
        /ChunkLoadError: Loading chunk/ // デプロイ直後のキャッシュのせいでなるエラー
      ],
      tracesSampleRate: 0.01,
      replaysSessionSampleRate: 1, // NOTE: stg/devは全て収集する,
      replaysOnErrorSampleRate: 1
    })
    this.sentry.getCurrentScope().setUser({ id: username })
    this.isInitializedSentry = true
    this.log('sentry initialized.')
  }

  initSessionReplay(username: string): void {
    if (this.replay) return
    const replay = this.sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        /^https:\/\/api\.(dev-reco\.net|stg-reco\.jp|reco\.education)\/.*/,
        /^https:\/\/cognito.*/,
        new RegExp('^' + window.location.origin.replaceAll('.', '\\.') + '/.*')
      ],
      networkCaptureBodies: true,
      beforeAddRecordingEvent: event => {
        // NOTE: マスキング対象である電話番号を breadcrumb として送信しないようにイベントごとハンドリングする (参照: https://gitlab.com/mates-pay/app/new_reco/reco_for_parent/-/issues/524)
        if (event.data.tag === 'breadcrumb' && event.data?.payload.data) {
          if (event.data.payload.category === 'ui.click') {
            if (event.data.payload.data.node.attributes.class === 'classroom-phone-number-btn') {
              return null
            }
          }
        }
        return sanitizerForReplayFrameEvent(event)
      }
    })
    this.sentry.getCurrentScope().setUser({ id: username })
    this.sentry.getClient()?.addIntegration?.(replay)
    this.replay = replay
    this.log('sentry replay initialized.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  identify(username: string, data: Record<string, string>): void {
    this.sentry.getCurrentScope().setUser({
      username
    })
  }

  stopSessionReplay(): void {
    if (!this.replay) return
    this.replay.stop()
  }

  restartSessionReplay(): void {
    if (!this.replay) return
    this.replay.start()
  }

  captureException(e: unknown): void {
    this.sentry.captureException(e)
  }

  log(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.log(
      `[${summary}]\x20`,
      ...copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  debug(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.debug(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  info(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.info(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  warn(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.warn(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }

  error(summary: string, ...props: any[]): void {
    const copied = cloneDeep(props)
    console.error(
      `[${summary}]\x20`,
      copied.map(it => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: it }))
    )
  }
}
