
import { defineComponent } from '@nuxtjs/composition-api'
import type { PropType } from 'vue'
import { type SnackBarLink } from '~/useCases/snackBar'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Snackbar',

  model: {
    prop: 'isOpened',
    event: 'close'
  },

  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    link: {
      type: Object as PropType<SnackBarLink>,
      default: null
    }
  }
})
