import { type ReplayFrameEvent } from '@sentry/browser'
import { cloneDeep } from '@/modules/util'
import {
  type Manager,
  type Teacher,
  type Student,
  type Parent,
  type CreateParentEmailInput,
  type UpdateParentEmailInput
} from '~/types/codegen/schema'

type MaybeJson = JustJson | NotJson
type JustJson = { type: 'json'; value: object }
type NotJson = { type: 'not-json'; value: string }
const parseMaybeJson = (maybeJson: string): MaybeJson => {
  try {
    const parsed = JSON.parse(maybeJson)
    return { type: 'json', value: parsed }
  } catch (e) {
    return { type: 'not-json', value: maybeJson }
  }
}

const MASKED_TEXT = '[Masked]'

const isRecord = (arg: unknown): arg is Record<string, unknown> => typeof arg === 'object' && arg !== null
const isManager = (arg: unknown): arg is Manager => isRecord(arg) && arg.__typename === 'Manager'
const isTeacher = (arg: unknown): arg is Teacher => isRecord(arg) && arg.__typename === 'Teacher'
const isStudent = (arg: unknown): arg is Student => isRecord(arg) && arg.__typename === 'Student'
const isParent = (arg: unknown): arg is Parent => isRecord(arg) && arg.__typename === 'Parent'

/**
 * 以下のケースでマスク化をしている
 * __typename: 'Manager'の name, furigana
 * __typename: 'Teacher'の name, furigana
 * __typename: 'Student'の name, furigana
 * __typename: 'Parent'の name, furigana
 * phoneNumber
 * emailAddress
 *
 * @param dangerouslyMutableTarget: 元のオブジェクトの値が変更されてしまうので、deepCopyしたものを引数として渡すのが推奨
 */
export const maskPropertiesOfEncryptionRequirements = ({
  dangerouslyMutableTarget: data
}: {
  /**
   *  dangerouslyMutableTarget: 元のオブジェクトの値が変更されてしまうので、deepCopyしたものを引数として渡すのが推奨
   */
  dangerouslyMutableTarget: unknown
}): unknown => {
  try {
    if (Array.isArray(data))
      return data.map(item => maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: item }))

    if (isManager(data) || isTeacher(data) || isStudent(data) || isParent(data)) {
      // 暗号化対象propertyを含むobjectの場合
      data.name = '[Masked]'
      data.furigana = '[Masked]'
    }

    if (isRecord(data) && data.password) data.password = '[Masked]'
    if (isRecord(data) && data.phoneNumber) data.phoneNumber = '[Masked]'
    if (isRecord(data) && data.emailAddress) data.emailAddress = '[Masked]'

    if (isRecord(data))
      return Object.entries(data).reduce(
        (maskedData: Record<string, unknown>, [key, value]: [key: string, value: unknown]) => {
          maskedData[key] = maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: value })
          return maskedData
        },
        {}
      )

    return data
  } catch {
    return 'Failed to mask'
  }
}

const isCreateParentEmailInput = (
  requestBody: unknown
): requestBody is { variables: { input: CreateParentEmailInput } } =>
  isRecord(requestBody) && requestBody.operationName === 'createParentEmail'

const isUpdateParentEmailInput = (
  requestBody: unknown
): requestBody is { variables: { input: UpdateParentEmailInput } } =>
  isRecord(requestBody) && requestBody.operationName === 'updateParentEmail'

/**
 * 暗号化要件に含まれるInputの値をマスクする
 * @param dangerouslyMutableTarget: 元のオブジェクトの値が変更されてしまうので、deepCopyしたものを引数として渡すのが推奨
 */
const maskRequestBody = ({
  dangerouslyMutableTarget: data
}: {
  /**
   *  dangerouslyMutableTarget: 元のオブジェクトの値が変更されてしまうので、deepCopyしたものを引数として渡すのが推奨
   */
  dangerouslyMutableTarget: unknown
}): unknown => {
  try {
    if (isCreateParentEmailInput(data) || isUpdateParentEmailInput(data)) {
      data.variables.input.emailAddress = MASKED_TEXT
    }
    return data
  } catch {
    return 'Failed to mask'
  }
}

export const sanitizerForReplayFrameEvent = (event: ReplayFrameEvent): ReplayFrameEvent => {
  try {
    /** Networkのrequest/response bodyをmask化 */
    if (
      isRecord(event.data) &&
      // event は SpanFrameEvent interface
      event.data.tag === 'performanceSpan' &&
      isRecord(event.data.payload) &&
      event.data.payload.op &&
      // event.data.payload は RequestFrame interface
      (event.data.payload.op === 'resource.fetch' || event.data.payload.op === 'resource.xhr') &&
      isRecord(event.data.payload.data) &&
      isRecord(event.data.payload.data.response) &&
      isRecord(event.data.payload.data.request) &&
      isRecord(event.data.payload.data.request.body) &&
      event.data.payload.data.response.body
    ) {
      // リクエストボディ
      const copiedRequestBody = cloneDeep(event.data.payload.data.request.body)
      event.data.payload.data.request.body = maskRequestBody({ dangerouslyMutableTarget: copiedRequestBody })
      // レスポンスボディ
      const parsed: MaybeJson =
        typeof event.data.payload.data.response.body === 'string'
          ? parseMaybeJson(event.data.payload.data.response.body)
          : {
              type: 'not-json',
              value: Object.toString.call(event.data.payload.data.response.body)
            }

      switch (parsed.type) {
        case 'json': {
          event.data.payload.data.response.body = Object.entries(parsed.value).map(([_, value]) => {
            const copied = cloneDeep(value)
            return maskPropertiesOfEncryptionRequirements({ dangerouslyMutableTarget: copied })
          })
          break
        }
        case 'not-json': {
          // do nothing
          // NOTE: JSONでないものに関しては特にマスキングしない
        }
      }
    }
    return event
  } catch {
    return event
  }
}
